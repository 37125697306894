import { Helmet } from "react-helmet";
import { useLangContext } from "./translateContext";
export default function SEO({ title, description, keywords }) {
  const { Lang } = useLangContext();
  return (
    <Helmet htmlAttributes={{ lang: Lang }}>
      <title> {title} </title>
      <meta name="description" content={description} />

      {/* TWITTER META TAGS */}
      {/* <meta name="twitter:card" content="summary_large_image" /> */}
      {/* <meta
        name="twitter:image"
        content="https://designcode.io/cloud/v2/twitter.jpg"
      /> */}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:creator" content="@qosic" />
      <meta name="twitter:site" content="@qosic" />
      <meta name="twitter:description" content={description} />
      {/* OPEN GRAPH META TAGS(FACEBOOK...) */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content="www.qosic.com" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:keywords" content={keywords} />
      {/* <meta
        property="og:image"
        content="https://designcode.io/cloud/v2/twitter.jpg"
      /> */}
    </Helmet>
  );
}
