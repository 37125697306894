import Wrapper from "../../components/sectionWrapper";
import Pretitle from "../../components/pretitle";
import { media } from "../../libs/media";
import Messages from "./translations/Reach.json";
import buttonText from "../../translations/button.json";
import { useLangContext } from "../../components/translateContext";
import { Link } from "react-router-dom";
import { Slide } from "react-reveal";
import { Icon } from "@iconify/react";
export default function ReachSection() {
  const { Lang } = useLangContext();
  return (
    <Wrapper childrenClassName=" relative py-10 " className="bg-qosdark">
      <div className="grid md:flex  justify-center justify-center">
        <div className="w-full md:w-6/12  py-6">
          {/* <Pretitle dataName={Messages[Lang].PreTitle} /> */}
          <p className="md:text-x3l text-qosorange">{Messages[Lang].PreTitle}</p>
          <h2 className="w-11/12 md:w-11/12 mt-4 text-qoswhite">
            {Messages[Lang].Title}
          </h2>

          <div className="w-full  flex mt-8   gap-4">
            <div className="pr-10">
              <p className="text-qoswhite md:text-x2l">
                {" "}
                {Messages[Lang].description}{" "}
              </p>
            </div>
          </div>

          <Link
            to="/offline-payment"
            className={`p-2 inline-block mt-12 md:hidden hover:text-qosdark font-bold rounded-full px-3 shadow-lg btn-sm max-w-max mx-auto text-qosgray text-opacity-90 m-2 bg-qosorange`}
          >
            {buttonText[Lang].productPart}
          </Link>
        </div>
        <div className="hidden md:flex w-full md:w-6/12 " style={{ marginTop: -50 }}>
          <img
            src={media.Quick.globe}
            alt="hello"
            width="800" // Specify the desired width in pixels
            height="550" // Specify the desired height in pixels
            viewBox="0 0 200 150"
            // className="sm:w-10/12   mx-auto md:w-11/12 mt-12 md:mt-0"
          />
        </div>
      </div>
      <div className=" w-full grid md:flex md:gap-4 justify-center mt-10">
        <div className="w-full md:w-4/12 md:p-6 ">
          <div className=" my-4 flex justify-start items-center align-center md:gap-3">
            <div>
              <h3 className="md:text-start text-qoswhite"> +30,000 </h3>
            </div>
            {/* <img
              className="opacity-80 h-8 w-8 mb-4 block md:mx-auto"
              src={security}
              alt="cross over technologies"
            /> */}
          </div>
          <p className="mt-1 pt-1 pb-10 text-qoswhite ">
            {Messages[Lang].description1}
          </p>
        </div>

        <div className="w-full md:w-4/12  md:p-6 ">
          <div className=" my-4 flex justify-start items-center align-center gap-3">
            <div>
              <h3 className="md:text-center text-qoswhite"> 95% </h3>
            </div>
            {/* <img
              className="opacity-80 h-8 w-8 mb-4 block md:mx-auto"
              src={security}
              alt="cross over technologies"
            /> */}
          </div>
          <p className="mt-1 pt-1 pb-10 text-qoswhite">
            {Messages[Lang].description2}
          </p>
        </div>

        <div className="w-full md:w-4/12  md:p-6">
          <div className=" my-4 flex justify-start items-center align-center gap-3">
            <div>
              <h3 className="md:text-center text-qoswhite"> 120 </h3>
            </div>
          </div>
          <p className="mt-1 pt-1 pb-10 text-qoswhite ">
            {Messages[Lang].description3}
          </p>
        </div>
      </div>
      <div className=" w-full grid md:flex gap-4 justify-center ">
        <div className="w-full md:w-4/12 md:p-6 ">
          <div className=" my-4 flex justify-start items-center align-center gap-3">
            <div>
              <h3 className="md:text-start text-qoswhite"> 0.3 </h3>
            </div>
            {/* <img
              className="opacity-80 h-8 w-8 mb-4 block md:mx-auto"
              src={security}
              alt="cross over technologies"
            /> */}
          </div>
          <p className="mt-1 pt-1 pb-10 text-qoswhite ">
            {Messages[Lang].description4}
          </p>
        </div>

        <div className="w-full md:w-4/12  md:p-6">
          <div className=" my-4 flex justify-start items-center align-center gap-3">
            <div>
              <h3 className="md:text-center text-qoswhite">99.50% </h3>
            </div>
            {/* <img
              className="opacity-80 h-8 w-8 mb-4 block md:mx-auto"
              src={security}
              alt="cross over technologies"
            /> */}
          </div>
          <p className="mt-1 pt-1 pb-10 text-qoswhite">
            {Messages[Lang].description5}
          </p>
        </div>

        <div className="w-full md:w-4/12  md:p-6">
          <div className=" my-4 flex justify-start items-center align-center gap-3">
            <div>
              <h3 className="md:text-center text-qoswhite"> 30+ currencies </h3>
            </div>
          </div>
          <p className="mt-1 pt-1 pb-10 text-qoswhite ">
            {Messages[Lang].description6}
          </p>
        </div>
      </div>
    </Wrapper>
  );
}
