import Wrapper from "../../components/sectionWrapper";
import Pretitle from "../../components/pretitle";
import { media } from "../../libs/media";
import { Code } from "../../libs/codeSamples";
import CodeBoard from "../../components/codeBoard";
import Messages from "./translations/DevPart.json";
import buttonText from "../../translations/button.json";
import { useLangContext } from "../../components/translateContext";
import { Link } from "react-router-dom";
import { Slide } from "react-reveal";
const { crossOverTech } = media.Icons[0];

export default function DevsSection() {
  const { Lang } = useLangContext();
  const Infos = Messages[Lang];
  return (
    <Wrapper id="webDev" className="bg-qosdark bg-opacity-50 py-20">
      <section className="md:flex justify-between">
        <div className="md:w-6/12 pr-14">
          <Pretitle
            titleStyle={`text-qosorange text-opacity-80`}
            iconStyle={`text-qosgray text-opacity-70`}
            dataName={Infos.PreTitle}
          />{" "}
          <h2 className="text-qoswhite mt-2 lg:mb-5 lg:pr-14 ">
            {Infos.Title}
          </h2>{" "}
          <span className="mt-4  md:w-12/12   text-qosgray text-opacity-70">
            {Infos["description0"]}
          </span>
          {/* <div className="mt-8 lg:flex items-center">
            <img
              className="h-12 w-20 mb-2 inline-block"
              src={crossOverTech}
              alt="cross over technologies"
            />

            <h4 className="text-qosgray text-opacity-80">
              {Infos["subTitle0"]}
            </h4>
          </div> */}
          <span className="mt-2 lg:w-12/12 text-qosgray text-opacity-70">
            {Infos["description1"]}
            </span>
            <div className="mt-6 md:mt-10">

            <Link
              to="/docs"
              className="px-8 inline-block rounded-lg bg-qosorange py-1 shadow-sm text-opacity-90 font-medium btn-sm max-w-max mx-auto m-2"
            >
              <p  className="text-qosgray text-base lg:text-Mobilw20 px-5 py-2 hover:text-opacity-70 font-semibold  inline-block md:ml-1">

              {Infos["button"]}
              </p>
            </Link>
            </div>
        
        </div>
        <div className="hidden md:flex md:w-6/12 mt-12 md:mt-0 overflow-x-scroll hideScroll md:-mr-5 lg:-mr-10">
          <Slide bottom>
            <CodeBoard modes={Code.LibsOverview()} />
          </Slide>
        </div>
      </section>
      {/* <Link
        to="/docs"
        className={`p-2 inline-block hover:text-qosdark font-bold mt-12 md:hidden rounded-full px-3 shadow-lg btn-sm max-w-max text-qosgray text-opacity-90 m-2 bg-qosorange`}
      >
        {buttonText[Lang].callTodocs}
      </Link> */}
    </Wrapper>
  );
}
