import LandingSection from "./LandingSection";
import Partners from "./PartnersSection";
import OfflineBussiness from "./OffBussinessSection";
import WebBussiness from "./WebBussinessSection";
import DevsSection from "./DevPart";
import QualitySection from "./QualitySection";
import StatsSection from "./StatsSection";
import PricingSection from "./PricingSection";
import QuickLaunch from "./QuickLaunch";
import NewPartners from "./NewPartnersSection";
import ReachSection from "./ReachSection";
import Secured from "./Secured";
import Secured2 from "./Secured2";

export default function LandingPage() {
  return (
    <>
      <div
      // style={{ background: "url(./pictures/authpageBackground.png) fixed" }}
      >
        <LandingSection />
      </div>
      <Partners />
      <QualitySection />
      {/* <PricingSection /> */}
      <QuickLaunch />
      <OfflineBussiness />

      <div id="webDev" className="bg-qosdark bg-opacity-90">
        <DevsSection />
      </div>
      <Secured />
      <ReachSection /> 
      <Secured2/>
    
      {/* <NewPartners /> */}
    
      
      {/* <WebBussiness /> */}

      {/* <StatsSection /> */}
    </>
  );
}
