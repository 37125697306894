import Wrapper from "../../components/sectionWrapper";
import Pretitle from "../../components/pretitle";
import { media } from "../../libs/media";
import Messages from "./translations/OfflineBussiness.json";
import buttonText from "../../translations/button.json";
import { useLangContext } from "../../components/translateContext";
import { Link } from "react-router-dom";
import { Slide } from "react-reveal";
import { Icon } from "@iconify/react";
export default function OfflineBussiness() {
  const { Lang } = useLangContext();
  return (
    <Wrapper
      childrenClassName="md:flex relative py-10 "
      className="bg-qoswhite"
    >
      <div className="grid md:flex justify-center justify-center">
        <div className="w-full md:w-6/12  py-6">
          {/* <Pretitle dataName={Messages[Lang].PreTitle} /> */}
          {/* <h3 className="w-11/12 md:w-11/12 md-mt-12 text-qosorange">{Messages[Lang].PreTitle}</h3> */}
          <h2 className="w-11/12 md:w-11/12 mt-10">{Messages[Lang].Title}</h2>

          <div className="w-full  flex mt-8 m-2  gap-4">
            <div className="flex">
              <Icon
                icon="icon-park-solid:check-one"
                height={24}
                style={{ color: "orange" }}
              />
            </div>
            <div className="pr-10">
              <p className="md:text-x2l"> {Messages[Lang].description} </p>
            </div>
          </div>

          <div className="w-full  flex mt-8 m-2  gap-4">
            <div className="flex">
              <Icon
                icon="icon-park-solid:check-one"
                height={24}
                style={{ color: "orange" }}
              />
            </div>
            <div className="pr-10">
              <p className="md:text-x2l"> {Messages[Lang].description1} </p>
            </div>
          </div>

          <div className="w-full  flex mt-8 m-2  gap-4">
            <div className="flex">
              <Icon
                icon="icon-park-solid:check-one"
                height={24}
                style={{ color: "orange" }}
              />
            </div>
            <div className="pr-10">
              <p className="md:text-x2l"> {Messages[Lang].description2} </p>
            </div>
          </div>

          <div className="flex justify-start gap-3">
            <div className="mt-6 md:mt-10">
              <Link to="/offline-payment">
                <a className="px-8 inline-block rounded-lg  bg-qosorange py-1 shadow-sm text-opacity-90 font-medium btn-sm max-w-max mx-auto m-2">
                  <p className="text-qosgray text-base lg:text-Mobilw20 px-5 py-2 hover:text-opacity-70 font-semibold  inline-block md:ml-1">
                    {buttonText[Lang].Learn}
                  </p>
                </a>
              </Link>
              <Link
                to="/contact-us"
                className="rounded-full relative px-3 ml-4  inline-flex items-center p-2 backdrop-blur-3xl text-opacity-70"
              >
                <p
                  className="text-qosorange text-base lg:text-Mobilw20 hover:text-opacity-70  font-semibold inline-block md:ml-1"
                
                >
                  {buttonText[Lang].saleButton}
                </p>
                <Icon
                icon="ion:chevron-forward"
                height={25}
                style={{ color: "orange", fontSize: "20px" }}
              />
              </Link>
            </div>
          </div>

          {/* <Link
            to="/offline-payment"
            className={`p-2 inline-block mt-12 md:hidden hover:text-qosdark font-bold rounded-full px-3 shadow-lg btn-sm max-w-max mx-auto text-qosgray text-opacity-90 m-2 bg-qosorange`}
          >
            {buttonText[Lang].productPart}
          </Link> */}
        </div>
        <div className="hidden md:flex  w-full md:w-6/12 lg:mt-10 lg:pl-10 lg:pt-10 lg:pb-10 ">
          <Slide bottom>
            <img
              src={media.Quick.uss}
              alt="hello"
              className="sm:w-10/12 h-full w-full  md:w-full mt-12 md:mt-0   max-h-full"
            />
          </Slide>
        </div>
      </div>
    </Wrapper>
  );
}
