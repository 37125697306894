import { Link } from "react-router-dom";
import Wrapper from "../../components/sectionWrapper";
import { media } from "../../libs/media";
import Messages from "./translations/Quick.json";
import { useLangContext } from "../../components/translateContext";
import buttonText from "../../translations/button.json";
import { Bounce } from "react-reveal";
export default function QuickLaunch() {
  const { Lang } = useLangContext();
  const Infos = { msg: Messages[Lang], button: buttonText[Lang] };
  return (
    <Wrapper
      childrenClassName="relative"
      className="bg-qoswhite mt-10 md:mt-0 pb-10 md:pb-20 z-10 overflow-hidden relative"
    >
      <div className=" relative flex-inline  md:text-center justify-center align-center w-full">
        {/* <div className="mb-4">
          <h4 className="text-qosorange">{Infos.msg.Quick}</h4>
        </div> */}

        <div className="w-full flex-inline  md:w-full gap-4  md:justify-center">
          <div className="w-full  ">
            <h3 className="md:pt-4 md:text-center md:text-Nwm md:w-12/12 lg:w-12/12 xl:12/12  ">
              {Infos.msg.HelloText}
            </h3>
          </div>
          <div className="w-full  ">
            <p className="md:pt-4 md:w-12/12 lg:w-12/12 xl:12/12 md:text-x2l md:px-40">
              {Infos.msg.HelloText1}
            </p>
          </div>
        </div>

        {/* <div className="mt-6 md:mt-10">
          <Link
            to="/pricing"
            className="rounded-full relative  inline-flex items-center p-2 backdrop-blur-3xl text-opacity-70"
          >
            <a className=" inline-block rounded-full bg-qosorange bg-opacity-80  px-6 shadow-sm text-opacity-90 font-medium btn-sm max-w-max mx-auto m-1">
              <p
                className="text-qosgray p-1.5 hover:text-qosdark font-bold  inline-block "
                style={{ fontSize: 18 }}
              >
                {Infos.msg.View}
              </p>
            </a>
          </Link>
        </div> */}
      </div>

      <div className="grid md:flex md:w-full  gap-4 mt-14">
        <div className="bg-qoswhite shadow w-full md:w-6/12 p-4 overflow-hidden transition-transform transform hover:scale-110">
          <div>
            <img
              src={media.Quick.left}
              alt=""
              className=" h-80   block mx-auto md:h-96  "
            />
          </div>
          <div className="p-4 grid gap-4 mb-6">
            <div className="pt-4">
              <span className="md:text-x4l font-bold ">{Infos.msg.Store}</span>
            </div>
            <div className="pt-2">
              <span className="md:text-x2l">{Infos.msg.StoreText}</span>
            </div>
          </div>
        </div>

        <div className="bg-qoswhite shadow w-full md:w-6/12 p-4 overflow-hidden transition-transform transform hover:scale-110">
          <div>
            <img
              src={media.Quick.right}
              alt=""
              className=" h-80   block mx-auto md:h-96  "
            />
          </div>
          <div className="p-4 grid gap-4 mb-6">
            <div className="pt-4">
              <span className="md:text-x4l font-bold ">{Infos.msg.Payment}</span>
            </div>
            <div className="pt-2 ">
              <span className="md:text-x2l">{Infos.msg.PaymentText}</span>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
