import React, { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import ReactDOM from "react-dom";
import "./prism-night-owl.css";
import "./index.css";
import LangsProvider from "./components/translateContext";
import App from "./App";

export function Loader() {
  return (
    <div className="absolute flex justify-around transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
      <div className="h-8 w-8 rounded-full bg-gradient-to-t from-qosblue to-qosred opacity-40  animate-pulse delay-0 mx-2"></div>
      <div className="h-8 w-8 rounded-full bg-gradient-to-t from-qosblue to-qosred opacity-40  animate-pulse delay-75 mx-2"></div>
      <div className="h-8 w-8 rounded-full bg-gradient-to-t from-qosblue to-qosred opacity-40  animate-pulse delay-100 mx-2"></div>
    </div>
  );
}
const Root = document.getElementById("root");

if (Root.hasChildNodes()) {
  ReactDOM.hydrate(
    // <React.StrictMode>
      <LangsProvider>
        <Router>
          <Suspense fallback={<Loader />}>
            <App />
          </Suspense>
        </Router>
      </LangsProvider>
    // </React.StrictMode>
    ,
    Root
  );
} else {
  ReactDOM.render(
    // <React.StrictMode>
      <LangsProvider>
        <Router>
          <Suspense fallback={<Loader />}>
            <App />
          </Suspense>
        </Router>
      </LangsProvider>
    // </React.StrictMode>
    ,
    Root
  );
}
