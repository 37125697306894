import { useEffect, useState } from "react";
import Wrapper from "../../components/sectionWrapper";
import Address from "../../components/address";
import PhoneInput from "react-phone-input-2";
import Messages from "./translations/index.json";
import { useLangContext } from "../../components/translateContext";
import buttonText from "../../translations/button.json";
import inputText from "../../translations/input.json";
import { media } from "../../libs/media";
import Server from "../../libs/server";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";

const { Facebook, Instagram, LinkedIn, Whatsapp } = media.Social;
const { mailSent } = media.Illustrations;
export default function SupportPage() {
  const { Lang } = useLangContext();
  const [Success, setSuccess] = useState(false);
  const [OtherReason, setOtherReason] = useState(false);
  const [Contact, setContact] = useState({});

  const Infos = {
    msg: Messages[Lang],
    input: inputText[Lang],
    button: buttonText[Lang],
  };

  function handleContactSender(e) {
    e.preventDefault();
    Server.Contact(Contact)
      .then((response) => response && setTimeout(() => setSuccess(true), 200))
      .catch((response) => console.log(response.response));
  }

  function handleInputChange(e) {
    setContact((prev) => {
      prev[e.target.name] = e.target.value;
      return { ...prev };
    });
  }

  useEffect(() => {
    console.log(Contact);
  }, [Contact]);

  return (
    <div className="pt-24 mb-10 md:mb-20 ">
      {/* <Wrapper
        childrenClassName="transform md:scale-90"
        className="py-24 md:py-4"
      > */}
      <div className=" px-4  md:px-10 lg:px-8 xl:px-8 xl:max-w-screen-xl mx-auto">
        <div className="flex flex-col mt-10 md:mt-20 pt-10 mb-10 text-center justify-center bg-qoswhite ">
          <h3 className="md:text-Nwm  text-qosblackText  ">
            {Infos.msg.Title}
          </h3>
          <p className="mt-2 md:mt-10 md:w-7/12 mx-auto text-center text-qosdark text-base md:text-dlg1">
            {Infos.msg.description}
          </p>
        </div>

        <div className="w-full flex justify-center mb:4 md:mb-40">
          <div className="h-20 w-9/12 bg-qoswhite flex  align-center gap-2">
            <Icon
              icon="carbon:search"
              height={30}
              style={{
                color: "#BFBFBF",
                position: "absolute",
                marginTop: 24,
                marginLeft: 18,
              }}
            />

            <input
              style={styles.input}
              className="h-20 pl-16 border border-qosorange rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
              placeholder={Infos.msg.search}
            />

            <p></p>
          </div>
        </div>

        <div className="w-full text-center my-20">
          <h4 className="md:text-Nwm  text-qosblackText  ">
            {Infos.msg.Title1}
          </h4>
        </div>
        <section className="w-full mt-20  ">
          <div className="flex w-full">
            <div className="w-1/2">
              <h4>{Infos.msg.chose}</h4>
            </div>

            <div className="w-7/12 ">
              <h4 className="md:mb-2 md:mt-0 my-4 text-opacity-70 ">
                {Infos.msg.question}
              </h4>
              <p className="md:text-Mobilp text-opacity-70  py-2">
                {Infos.msg.reponse}
              </p>

              <h4 className="md:mb-2 md:mt-4 text-opacity-70  my-4">
                {Infos.msg.question}
              </h4>
              <p className="md:text-Mobilp text-opacity-70  py-2">
                {Infos.msg.reponse}
              </p>

              <h4 className="md:mb-2 md:mt-4  text-opacity-70  my-4">
                {Infos.msg.question}
              </h4>
              <p className="md:text-Mobilp  text-opacity-70  py-2">
                {Infos.msg.reponse}
              </p>
            </div>
          </div>
        </section>

        <section className="w-full  mt-20">
          <div className="flex w-full">
            <div className="w-1/2">
              <h4>{Infos.msg.chose1}</h4>
            </div>

            <div className="w-7/12 ">
              <h4 className="md:mb-2 md:mt-0 my-4 text-opacity-70 ">
                {Infos.msg.question1}
              </h4>
              <p className="md:text-Mobilp text-opacity-70  py-2">
                {Infos.msg.reponse1}
              </p>

              <h4 className="md:mb-2 md:mt-4 text-opacity-70  my-4">
                {Infos.msg.question}
              </h4>
              <p className="md:text-Mobilp text-opacity-70  py-2">
                {Infos.msg.reponse}
              </p>
            </div>
          </div>
        </section>

        <section className="w-full  mt-20">
          <div className="flex w-full">
            <div className="w-1/2">
              <h4>{Infos.msg.chose2}</h4>
            </div>

            <div className="w-7/12 ">
              <h4 className="md:mb-2 md:mt-0 my-4 text-opacity-70 ">
                {Infos.msg.question2}
              </h4>
              <p className="md:text-Mobilp text-opacity-70  py-2">
                {Infos.msg.reponse2}
              </p>
            </div>
          </div>
        </section>
      </div>

      <div className="mt-4 md:mt-40 w-full my-10 justify-center align-center flex-col flex">
        <div className=" text-center">
          <h3 className="md:text-Nwm  text-qosb">{Infos.msg.Title2}</h3>
          <h3 className="md:text-Nwm  text-qosb">{Infos.msg.Title21}</h3>
        </div>

        <div className="bg-qosblue flex justiy-center align-center w-full"></div>
      </div>

      <div className="flex w-full my-10 md:mb-40 justify-center align-center">
        <button className="px-8 inline-block rounded-full w-2/4 bg-qosorange py-1 shadow-sm text-opacity-90 font-medium  m-2">
          <Link
            to={"/contact-support"}
            className="text-qosgray text-base lg:text-Nw py-4 font-semibold  inline-block md:ml-1"
          >
            {Infos.msg.Contact}
          </Link>
        </button>
      </div>
      {/* </Wrapper> */}
    </div>
  );
}

const styles = {
  input: {
    borderRadius: 4,
  },
  button: {
    height: "28",
    borderRadius: 8,
  },
};
