/*********  1- LOGOS IMPORTATION **************/

// 1.1 -qos logos
import qosLogoWhite from "../images/QOS/QOS_WHITE-min.webp";
import qosLogoBlack from "../images/QOS/QOS_BLACK-min.webp";
import background404 from "../images/backgrounds/astro-min.webp";
import moov from "../images/QOS/moov.png";
import mtn from "../images/QOS/mtn.jpeg";
import Mastercard from "../images/QOS/Mastercard.png";
import togo from "../images/QOS/togo.png";
import Ivoire from "../images/QOS/ivoire.jpg";
import Ivoirea from "../images/QOS/Ivoire.png";
import Right from "../images/QOS/right.png";
import Left from "../images/QOS/left.png";
import ussdImage from "../images/QOS/ussdImage.png";
import globe1 from "../images/QOS/globe.svg";
import world from "../images/QOS/world.svg";
import secureleft from "../images/QOS/secureleft.svg";
import pringleft from "../images/QOS/pringleft.svg";
import secureright from "../images/QOS/secureright.svg";
import websitetop from "../images/QOS/websitetop.svg";
import paymentlinkRight from "../images/QOS/paymentlinkRight.png";
import paymentlinkGlobe from "../images/QOS/paymentlinkGlobe.png";
import paymentLinkOverview from "../images/QOS/paymentLinkOverview.png";
// import paymentlinkphone from "../images/QOS/paymentlinkphone.png";
import paymentlinkphone from "../images/QOS/paymentlinkphone.svg";
import paymentlinkphone1 from "../images/QOS/paymentlinkphone1.svg";
import paymentlinkphone2 from "../images/QOS/paymentlinkphone2.svg";

// USSD IMAGES
import UssdHero from "../images/QOS/UssdHero.svg";
import UssdOptions from "../images/QOS/UssdOptions.svg";
import UssdTop from "../images/QOS/UssdTop.svg";
// ONLINE PAYMENT
import OnlineLeft from "../images/QOS/OnlineLeft.svg";
import OnlineRight from "../images/QOS/OnlineRight.svg";
import CommentOneOne from "../images/QOS/CommentOneOne.svg";
import CommentThreeThree from "../images/QOS/CommentThreeThree.svg";
import CommentTwoTwo from "../images/QOS/CommentTwoTwo.svg";

// CHECKOUT
import CheckoutTop from "../images/QOS/CheckoutTop.svg";
import CheckOutFocusRight from "../images/QOS/CheckOutFocusRight.svg";
import ChechOutOptimizedLeft from "../images/QOS/ChechOutOptimizedLeft.svg";
import CheckOutCustomRight from "../images/QOS/CheckOutCustomRight.svg";
import CheckOutGetRight from "../images/QOS/CheckOutGetRight.svg";

// TRANSFERT
import TransferOne from "../images/QOS/TransferOne.svg";
import TransferTwo from "../images/QOS/TransferTwo.svg";
import TransferTop from "../images/QOS/TransferTop.svg";
import TransferThree from "../images/QOS/TransferThree.svg";

// 1.2- CUSTUMERS AND PARTNERS LOGOS
import partner0 from "../images/partners/africainevie-min.webp";
import partner1 from "../images/partners/altlantique-min.webp";
import partner2 from "../images/partners/arab-min.webp";
import partner4 from "../images/partners/crs-min.webp";
import partner5 from "../images/partners/culture-min.webp";
import partner6 from "../images/partners/farmerline-min.webp";
import partner8 from "../images/partners/friklabel-min.webp";
import partner10 from "../images/partners/atlantique.png";
import partner11 from "../images/partners/itNum-min.webp";
import partner12 from "../images/partners/kea-min.webp";
import partner13 from "../images/partners/Mtn-logo-svg.svg.png";
import partner16 from "../images/partners/padme-min.webp";
import partner17 from "../images/partners/paiementPro-min.webp";
import partner18 from "../images/partners/Pieuve-min.webp";
import partner19 from "../images/partners/sirocco-pay.jpg";
import partner20 from "../images/partners/Logo_SUNU.jpg";
import partner22 from "../images/partners/zeyi.png";
import partner23 from "../images/partners/MasterCard_Logo.svg.png";
import partner24 from "../images/partners/Moov_Africa_logo.png";
import partner25 from "../images/partners/Izichange.png";

// 1.2-1 NEW PARTNAIRE IMAGES

import newpartner0 from "../images/partners/abcnew.png";
import newpartner1 from "../images/partners/atlantiquenew.png";
import newpartner2 from "../images/partners/farmelinenew.png";
import newpartner4 from "../images/partners/gozemnew.png";
import newpartner5 from "../images/partners/itnumnew.png";
import newpartner6 from "../images/partners/mtnnew.png";
import newpartner7 from "../images/partners/paiementpronew.png";
import newpartner8 from "../images/partners/siroccopaynew.png";
import newpartner10 from "../images/partners/zeyinew.png";

// STORE IMAGES

import Store1 from "../images/partners/Store1.svg";
import Store2 from "../images/partners/Store2.svg";
import Store3 from "../images/partners/Store3.svg";
import Store4 from "../images/partners/Store4.svg";
import Store5 from "../images/partners/Store5.svg";
import Store6 from "../images/partners/Store6.svg";
import StoreLeft from "../images/partners/StoreLeft.svg";
import StoreRight from "../images/partners/StoreRight.svg";
import StoreStep1 from "../images/partners/StoreStep1.svg";
import StoreStep2 from "../images/partners/StoreStep2.svg";
import StoreStep3 from "../images/partners/StoreStep3.svg";
import StoreTop from "../images/partners/StoreTop.svg";

import StoreEmpBottomRight from "../images/QOS/StoreEmpBottomRight.svg";
import StoreEmpBottomLeft from "../images/QOS/StoreEmpBottomLeft.svg";
import StoreEmpTopLeft from "../images/QOS/StoreEmpTopLeft.svg";
import StoreEmpTopRight from "../images/QOS/StoreEmpTopRight.svg";

// import partner24 from "../images/partners/MasterCard_Logo.svg.png";
import dss from "../images/partners/dss.png";

// 1.3- CLIENT_ICON
import github from "../images/clients/github.png";
import wp from "../images/clients/wp.png";

/*********  2- ICONS IMPORTATION **************/

// 2.1-Icon Theme 2
import security1 from "../icons/theme2/033-credit card.svg";
import easyHandling1 from "../icons/theme2/005-credit card.svg";
import lowFees1 from "../icons/theme2/001-credit card.svg";
import online1 from "../icons/theme2/048-online payment.svg";
import shareLink1 from "../icons/theme2/046-transfer.svg";
import crossOverTech from "../icons/theme2/003-cut.svg";
import digitalFinance1 from "../icons/theme2/032-bank.svg";
import offline1 from "../icons/theme2/041-chip.svg";
import credit from "../icons/theme2/credit.svg";
import shoppi from "../icons/theme2/shoppi.svg";

// 2.3-funcionnal icons
import checkFirst from "../icons/checkWhite.svg";

// 2.4 - FLAGS
import Benin from "../images/flags/benin.webp";
import Nigeria from "../images/flags/naija.webp";
import Togo from "../images/flags/togo.webp";
import fr from "../images/flags/fr.webp";
import en from "../images/flags/en.webp";

/*********  1- IMAGES AND ILLUSTRATIONS IMPORTATION **************/

import qosLandingi from "../images/illustrations/illustration4-min.webp";
import onlinePi from "../images/illustrations/illustration15-min.webp";
import USSDi from "../images/illustrations/illustration10-min.webp";
import landingDashi from "../images/illustrations/illustration3-min.webp";
import USSDflowi from "../images/illustrations/illustration8-min.webp";
import ussdBigCapture from "../images/illustrations/illustration1-min.webp";
import mailSent from "../images/illustrations/mail-min.webp";

/* social media */

import Facebook from "../icons/social/social1.webp";
import Instagram from "../icons/social/social2.webp";
import LinkedIn from "../icons/social/social3.webp";
import Whatsapp from "../icons/social/whatsapp.png";

/* wordpress plugin */

import step1 from "../images/Tutorials/plugins/wordpress/image1.png";
import step2 from "../images/Tutorials/plugins/wordpress/image2.png";
import step3 from "../images/Tutorials/plugins/wordpress/image3.png";
import step4 from "../images/Tutorials/plugins/wordpress/image4.png";
import step5 from "../images/Tutorials/plugins/wordpress/image5.png";
import step6 from "../images/Tutorials/plugins/wordpress/image6.png";
import step7 from "../images/Tutorials/plugins/wordpress/image7.jpg";
import step8 from "../images/Tutorials/plugins/wordpress/image8.png";
import step9 from "../images/Tutorials/plugins/wordpress/image9.png";
import step10 from "../images/Tutorials/plugins/wordpress/image10.png";
import step11 from "../images/Tutorials/plugins/wordpress/image11.png";
import step12 from "../images/Tutorials/plugins/wordpress/image12.png";

export const media = {
  Illustrations: {
    LandingPage: {
      World: world,
      firstIllustration: websitetop,
      secondIllustration: landingDashi,
      altFirstImage: ussdBigCapture,
    },
    onlinePaymentPage: {
      firstIllustration: onlinePi,
    },
    offlinePaymentPage: {
      firstIllustration: USSDi,
      secondIllustration: USSDflowi,
    },
    mailSent,
  },
  Icons: [
    {
      crossOverTech: crossOverTech,
    },
    {
      security: security1,
      easyHandling: easyHandling1,
      lowFees: lowFees1,
      online: online1,
      shareLink: shareLink1,
      digitalFinance: digitalFinance1,
      offline: offline1,
      credit: credit,
      shoppi: shoppi,
    },
    {
      check: checkFirst,
    },
  ],
  Social: {
    Facebook,
    Instagram,
    LinkedIn,
    Whatsapp,
    dss,
  },
  Quick: {
    right: Right,
    left: Left,
    uss: ussdImage,
    globe: globe1,
  },
  Secure: {
    Left: secureleft,
    Right: secureright,
  },
  Pricing: {
    Pice: pringleft,
  },
  Logos: {
    moov: moov,
    mtn: mtn,
    togo: togo,
    Mastercard: Mastercard,
    Ivoire: Ivoire,
    qos: {
      qosLogoWhite: qosLogoWhite,
      qosLogoBlack: qosLogoBlack,
    },
    Links: {
      paymentlinkRight: paymentlinkRight,
      paymentlinkGlobe: paymentlinkGlobe,
      paymentLinkOverview: paymentLinkOverview,
      paymentlinkphone: paymentlinkphone,
      paymentlinkphone1: paymentlinkphone1,
      paymentlinkphone2: paymentlinkphone2,
    },
    Ussd: {
      ussdHero: UssdHero,
      ussdOptions: UssdOptions,
      ussdTop: UssdTop,
    },
    Online: {
      OnlineLeft: OnlineLeft,
      OnlineRight: OnlineRight,
      CommentThreeThree: CommentThreeThree,
      CommentTwoTwo: CommentTwoTwo,
      CommentOneOne: CommentOneOne,
    },
    Transfer: {
      TransferOne: TransferOne,
      TransferTop: TransferTop,
      TransferThree: TransferThree,
      TransferTwo: TransferTwo,
    },

    CheckOut: {
      CheckoutTop: CheckoutTop,
      CheckOutFocusRight: CheckOutFocusRight,
      ChechOutOptimizedLeft: ChechOutOptimizedLeft,
      CheckOutCustomRight: CheckOutCustomRight,
      CheckOutGetRight: CheckOutGetRight,
    },

    Store: {
      Store1: Store1,
      Store2: Store2,
      Store3: Store3,
      Store4: Store4,
      Store5: Store5,
      Store6: Store6,
      StoreLeft: StoreLeft,
      StoreRight: StoreRight,
      StoreStep1: StoreStep1,
      StoreStep2: StoreStep2,
      StoreStep3: StoreStep3,
      StoreTop: StoreTop,
      StoreEmpBottomRight: StoreEmpBottomRight,
      StoreEmpTopRight: StoreEmpTopRight,
      StoreEmpBottomLeft: StoreEmpBottomLeft,
      StoreEmpTopLeft: StoreEmpTopLeft,
    },
    StorePartner: [
      Store1,
      Store2,
      Store3,
      Store4,
      Store5,
      Store6,
  ],
    Newcustomers: [
      newpartner0,
      newpartner1,
      newpartner2,
      newpartner4,
      newpartner5,
      newpartner6,
      newpartner7,
      newpartner8,
      newpartner10,
    ],

    customers: [
      partner23,
      partner25,
      partner13,
      partner24,
      partner19,
      partner20,
      partner10,
      partner22,
      // partner0,
      // partner1,
      // partner2,
      // partner4,
      // partner5,
      // partner6,
      // partner8,
      // partner10,
      // partner11,
      // partner12,
      // partner13,
      // partner16,
      // partner17,
      // partner18,
      // partner19,
      // partner20,
    ],
    LandingCustomers: [
      // partner0,

      // partner23,
      partner10,
      partner25,
      // partner13,
      // partner24,
      // partner19,
      // partner20,
    
      partner22,
      // partner5,
      // partner8,
      // partner6,
      // partner4,

      // partner2,
      // partner11,
    ],
    other: {
      github: github,
      wordpress: wp,
      mtn: partner13,
    },
  },
  Flags: {
    Benin: Benin,
    Nigeria: Nigeria,
    Togo: Togo,
    Ivoire: Ivoirea,
    en: en,
    fr: fr,
  },
  background404,
  Plugin: {
    wordpress: [
      step1,
      step2,
      step3,
      step4,
      step5,
      step6,
      step7,
      step8,
      step9,
      step10,
      step11,
      step12,
    ],
  },
};
