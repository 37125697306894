import { Link } from "react-router-dom";

export function LinkIcon({ target, icon=false, link, name, description = false }) {
  return (
    <Link className="flex items-center group" target={target} to={link}>
      {icon && (
        <img
          className="inline-block h-8 w8 md:h-8 md:w-8"
          src={icon}
          alt="hello"
        />
      )}
      <div className="ml-4">
        <p className="text-qosblackText text-msm font-semibold hover:text-qosdark md:text-dsm group-hover">
          {" "}
          {name}{" "}
        </p>
        {description && <p className="text-msm pt-1">{description}</p>}
      </div>
    </Link>
  );
}
