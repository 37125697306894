import Wrapper from "../../components/sectionWrapper";
import { media } from "../../libs/media";
import { useLangContext } from "../../components/translateContext";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import Messages from "./translations/QualitySection.json";
const { security, lowFees, easyHandling } = media.Icons[1];
export default function QualitySection() {
  const { Lang } = useLangContext();
  const Infos = Messages[Lang];
  return (
    <Wrapper className="md:py-8 bg-qoswhite  md:mt-12 md:pt-16">
      <div>
        <div className="my-4 text-left">
          <p className=" text-qosblackText text-MobilwNw md:text-Nwm font-semibold md:text-center"> {Infos["our"].Title} </p>
        </div>
        <p className="text-qosblackgray px-0  md:mt-16 md:px-32 text-left md:text-center text-xl md:text-x2l" >
          {Infos["our"].description}
        </p>
        {/* <p className="text-qosblackgray mt-1 text-left md:text-center  text-xl md:text-x2l" >
          {Infos["our"].description1}
        </p> */}
      </div>

      <div className=" w-full grid md:flex md:w-full  mt-6 gap-4 justify-center  md:mt-16">
        <div className="w-full md:w-4/12  rounded-xl  md:p-10 ">
          <div className="my-4 flex justify-start items-center align-center gap-4">
            <div className="bg-qosgray w-20 h-20 rounded-full justify-center items-center flex">
              <Icon
                icon="icon-park-outline:api"
                height={40}
                style={{ color: "black", fontSize: "20px" }}
              />
            </div>
           
            {/* <img
              className="opacity-80 h-8 w-8 mb-4 block md:mx-auto"
              src={security}
              alt="cross over technologies"
            /> */}
          </div>
            <div>
              <h3 className="md:text-left text-qosblackText md:mt-5"> {Infos.API} </h3>
            </div>
          <p className="mt-2 pt-4 pb-10 text-xl text-qosblackgray" >
          {Infos['our'].Description2}
          </p>
        

          <div className="w-auto flex justify-start md:justify-start mb-12 ">
            <Link
              to="/online-payment"
              className="rounded-full relative inline-flex items-center  backdrop-blur-3xl text-opacity-70"
            >
              <p
                className="text-qosorange flex whitespace-nowrap font-bold hover:text-qosdark "
                style={{ fontSize: 20 }}
              >
                {Infos.learn}
              </p>
              <Icon
                icon="ion:chevron-forward"
                height={20}
                style={{ color: "orange", fontSize: "20px" }}
              />
            </Link>
          </div>
        </div>

        <div className="w-full md:w-4/12  rounded-xl  md:p-10 ">
          <div className="my-4 flex justify-start items-center align-center gap-4">
            <div className="bg-qosgray w-20 h-20 rounded-full justify-center items-center flex">
              <Icon
                icon="streamline:shopping-cart-3-shopping-cart-checkout"
                height={35}
                style={{ color: "black", fontSize: "20px" }}
              />
            </div>
           
            {/* <img
              className="opacity-80 h-8 w-8 mb-4 block md:mx-auto"
              src={security}
              alt="cross over technologies"
            /> */}
          </div>
          <div>
              <h3 className="md:text-left md:mt-5"> {Infos.Checkout} </h3>
            </div>
          <p className="mt-2 pt-4 pb-10 text-xl" >
          {Infos['our'].Description3}
          </p>



          <div className="w-auto flex justify-start md:justify-start mb-12 ">
            <Link
              to="/checkout"
              className="rounded-full relative inline-flex items-center  backdrop-blur-3xl text-opacity-70"
            >
              <p
                className="text-qosorange flex whitespace-nowrap font-bold hover:text-qosdark "
                style={{ fontSize: 20 }}
              >
                {Infos.learn}
              </p>
              <Icon
                icon="ion:chevron-forward"
                height={20}
                style={{ color: "orange", fontSize: "20px" }}
              />
            </Link>
          </div>
        </div>

        <div className="w-full md:w-4/12  rounded-xl  md:p-10 ">
          <div className="my-4 flex justify-start items-center align-center gap-4">
            <div className="bg-qosgray w-20 h-20 rounded-full justify-center items-center flex">
              <Icon
                icon="bi:send"
                height={30}
                style={{ color: "black", fontSize: "20px" }}
              />
            </div>
           
            {/* <img
              className="opacity-80 h-8 w-8 mb-4 block md:mx-auto"
              src={security}
              alt="cross over technologies"
            /> */}
          </div>
          <div>
              <h3 className="md:text-left md:mt-5"> {Infos.Transfer} </h3>
            </div>
          <p className="mt-2 pt-4 pb-10 text-xl">
          {Infos['our'].Description4}
          </p>

          <div className="w-auto flex justify-start md:justify-start mb-12 ">
            <Link
              to="/transfer"
              className="rounded-full relative inline-flex items-center  backdrop-blur-3xl text-opacity-70"
            >
              <p
                className="text-qosorange flex whitespace-nowrap font-bold hover:text-qosdark pr-3"
                style={{ fontSize: 20 }}
              >
                {Infos.learn}
              </p>
              <Icon
                icon="ion:chevron-forward"
                height={20}
                style={{ color: "orange", fontSize: "20px" }}
              />
            </Link>
          </div>
        </div>
      </div>

      {/* <div className=" w-full grid md:flex md:w-full gap-4 justify-center mt-10"> */}
        {/* <div className="w-full md:w-4/12 bg-qosgray rounded-xl  p-6 pb-8 ">
          <div className="my-4 flex justify-start items-center align-center gap-4">
            <div className="bg-qoswhite w-16 h-16 rounded-full justify-center items-center flex">
              <Icon
                icon="icon-park-outline:bank-transfer"
                height={25}
                style={{ color: "orange", fontSize: "20px" }}
              />
            </div>
            <div>
              <h3 className="md:text-center"> {Infos.Payment} </h3>
            </div>
          </div>

          <div className="mt-4 md:-ml-5">
            <div className=" w-auto flex justify-start ">
              <div className=" flex justify-center items-center align-center gap-1">
                <div className=" w-16 h-16 rounded-full justify-center items-center flex">
                  <Icon
                    icon="material-symbols:circle-outline"
                    height={25}
                    style={{ color: "orange", fontSize: "20px" }}
                  />
                </div>
                <div>
                  <p className="md:text-center text-xl"> {Infos.Mobile} </p>
                </div>
              </div>
            </div>
            <div className="w-auto flex justify-start ">
              <div className=" flex justify-center items-center align-center gap-1">
                <div className=" w-16 h-16 rounded-full justify-center items-center flex">
                  <Icon
                    icon="material-symbols:circle-outline"
                    height={25}
                    style={{ color: "orange" }}
                  />
                </div>
                <div>
                  <p className="md:text-center text-xl"> {Infos.Card} </p>
                </div>
              </div>
            </div>
            <div className="w-auto flex justify-start mb-4 ">
              <div className=" flex justify-center items-center align-center gap-1">
                <div className=" w-16 h-16 rounded-full justify-center items-center flex">
                  <Icon
                    icon="material-symbols:circle-outline"
                    height={25}
                    style={{ color: "orange", fontSize: "20px" }}
                  />
                </div>
                <div>
                  <p className="md:text-center text-xl"> {Infos.USSD} </p>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="w-full md:w-4/12 bg-qosgray rounded-xl  p-6 ">
          <div className="my-4 flex justify-start items-center align-center gap-4">
            <div className="bg-qoswhite w-16 h-16 rounded-full justify-center items-center flex">
              <Icon
                icon="icons8:down-round"
                height={25}
                style={{ color: "orange", fontSize: "20px" }}
              />
            </div>
            <div>
              <h3 className="md:text-center"> {Infos.Faster} </h3>
            </div>
        
          </div>

          <div className="mt-4 md:-ml-5">
            <div className=" w-auto flex justify-start ">
              <div className=" flex justify-center items-center align-center gap-1">
                <div className=" w-16 h-16 rounded-full justify-center items-center flex">
                  <Icon
                    icon="material-symbols:circle-outline"
                    height={25}
                    style={{ color: "orange", fontSize: "20px" }}
                  />
                </div>
                <div>
                  <p className="md:text-center text-xl"> {Infos.Payouon} </p>
                </div>
              </div>
            </div>
            <div className="w-auto flex justify-start ">
              <div className="flex justify-center items-center align-center gap-1">
                <div className=" w-16 h-16 rounded-full justify-center items-center flex">
                  <Icon
                    icon="material-symbols:circle-outline"
                    height={25}
                    style={{ color: "orange" }}
                  />
                </div>
                <div>
                  <p className="md:text-center text-xl"> {Infos.Payoutsche} </p>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="w-full md:w-4/12 bg-qosgray rounded-xl  p-6 ">
          <div className="my-4 flex justify-start items-center align-center gap-4">
            <div className="bg-qoswhite w-16 h-16 rounded-full justify-center items-center flex">
              <Icon
                icon="bx:support"
                height={24}
                style={{ color: "orange", fontSize: "20px" }}
              />
            </div>
            <div>
              <h3 className="md:text-center"> {Infos.Support} </h3>
            </div>
        
          </div>

          <div className="mt-4 md:-ml-5">
            <div className=" w-auto flex justify-start ">
              <div className=" flex justify-center items-center align-center gap-1">
                <div className=" w-16 h-16 rounded-full justify-center items-center flex">
                  <Icon
                    icon="material-symbols:circle-outline"
                    height={25}
                    style={{ color: "orange", fontSize: "20px" }}
                  />
                </div>
                <div>
                  <p className="md:text-center text-xl"> {Infos.Phone} </p>
                </div>
              </div>
            </div>
            <div className="w-auto flex justify-start ">
              <div className=" flex justify-center items-center align-center gap-1">
                <div className=" w-16 h-16 rounded-full justify-center items-center flex">
                  <Icon
                    icon="material-symbols:circle-outline"
                    height={25}
                    style={{ color: "orange" }}
                  />
                </div>
                <div>
                  <p className="md:text-center text-xl"> {Infos.Standard} </p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
 
      {/* </div> */}
    </Wrapper>
  );
}

{
  /* <div className="md:grid grid-flow-col gap-x-6 mt-8">
<div>
  <div className="my-4">
    <img
      className="opacity-80 h-8 w-8 mb-4 block md:mx-auto"
      src={security}
      alt="cross over technologies"
    />
    <h4 className="md:text-center"> {Infos["security"].Title} </h4>
  </div>
  <p className="mt-2 text-justify">{Infos["security"].description}</p>
</div>
<div>
  <div className="my-4">
    <img
      className="opacity-80 h-8 w-8 mb-4 block md:mx-auto"
      src={easyHandling}
      alt="cross over technologies"
    />
    <h4 className="md:text-center"> {Infos["handle"].Title} </h4>
  </div>
  <p className="mt-2 text-justify">{Infos["handle"].description}</p>
</div>
<div>
  <div className="my-4">
    <img
      className="opacity-80 h-8 w-8 mb-4 block md:mx-auto"
      src={lowFees}
      alt="cross over technologies"
    />
    <h4 className="md:text-center"> {Infos["fees"].Title} </h4>
  </div>
  <p className="mt-2 text-justify">{Infos["fees"].description}</p>
</div>
</div> */
}
