import { Link } from "react-router-dom";
import Wrapper from "../../components/sectionWrapper";
import { media } from "../../libs/media";
import Messages from "./translations/LandingSection.json";
import { useLangContext } from "../../components/translateContext";
import buttonText from "../../translations/button.json";
import { Icon } from "@iconify/react";
import { Bounce } from "react-reveal";
export default function LandingSection() {
  const { Lang } = useLangContext();
  const Infos = { msg: Messages[Lang], button: buttonText[Lang] };
  return (
    <Wrapper
      childrenClassName="md:flex pt-14  relative"
      className="bg-qoswhite md:pb-6 md:pb-20 z-10 overflow-hidden relative"
    >
      {/* <div
        id="Landing1"
        className="md:w-6/12 z-0 opacity-30 -mt-5 absolute md:mt-10 block mx-auto md:h-full md:-right-8 lg:-right-10"
      ></div> */}
      {/* <img
          src={media.Illustrations.LandingPage.World}
          alt=""
          height={100}
          className="md:w-11/12 lg:w-11/12  h-90 rounded-full z-0  pt-6 md:mt-16 block  md:h-108 md:-right-72 lg:-right-72 md:absolute"
        /> */}
      <Bounce top>
        <img
          src={media.Illustrations.LandingPage.firstIllustration}
          alt=""
          className="hidden h-50 absolute bg-opacity-100 md:w-6/12 lg:flex lg:w-11/12  lg:h-120  lg:z-0  pt-6 md:-mt-2 block   lg:-right-72  md:absolute"
        />
      </Bounce>

      {/* <div
        id="Landing1"
        className="md:w-6/12 z-0 opacity-30 -mt-5 absolute md:mt-5 block mx-auto md:h-full md:-right-8 lg:-right-10"
      ></div> */}
      {/* <Bounce top  >
        <img
          src={media.Illustrations.LandingPage.firstIllustration}
          alt=""
          className="md:w-6/12 lg:w-11/12 mb-16 h-110 rounded-full z-0 -mt-96  pt-6 md:mt-16 block  md:h-108 md:-right-196  md:absolute"
        />
      </Bounce> */}
      <div className="z-10 relative  flex-inline md:mb-10 md:mb-24 justify-center md:justify-left  md:align-left w-full">
        <div className="mt-20 w-full ">
          <h2 className="text-qosblackText tracking-wider md:leading-normal   text-Mobilw w-full text-center md:text-x5l  md:text-left md:text-left md:pt-24 md:md:w-6/12 lg:w-6/12 xl:w-6/12 ">
            {Infos.msg.HelloText} 
          </h2>
       
          <p className="text-qosblackgray text-xl mt-6 text-center md:mt-0 md:text-x3l w-11/12 lg:w-6/12 text-left md:text-left lg:w-5/12 my-2 md:mt-10">
            {Infos.msg.description}
          </p>
        </div>

        <div className="mt-4 md:mt-14">
          <a
            href="https://dashboard.qosic.com/signup"
            className="px-8 inline-block rounded-lg bg-qosorange py-1 shadow-sm text-opacity-90 font-medium btn-sm max-w-max mx-auto m-2"
          >
            <p className="text-qosgray text-base lg:text-Mobilw20 px-5 py-2 hover:text-opacity-70 font-semibold  inline-block md:ml-1">
              {Infos.button.registerButton}
            </p>
          </a>
          <Link
            to="/contact-us"
            className="rounded-full relative px-3 ml-4  inline-flex items-center p-2 backdrop-blur-3xl text-opacity-70"
          >
            <p className="text-qosorange text-base lg:text-Mobilw20 hover:text-opacity-70  font-semibold inline-block md:ml-1">
              {Infos.button.saleButton}
            </p>
            {/* <span className="fi fi-rr-angle-small-right mt-1 ml-2 font-bold self-end text-mmd"></span> */}
            <Icon
              icon="ion:chevron-forward"
              height={25}
              style={{ color: "orange", fontSize: "20px" }}
            />
          </Link>
        </div>
      </div>
    </Wrapper>
  );
}
