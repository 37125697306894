import { Link } from "react-router-dom";
import { media } from "../../libs/media";
const { background404 } = media;
export function PageNotFound() {
  function goBack() {
    window.history.back();
  }
  return (
    <div className="relative h-screen w-screen">
      <div
        id="Landing1"
        className="h-80 w-80 opacity-40 absolute transform -translate-x-1/2 -translate-y-1/2 top-1/4 left-1/4"
      >
        <img src={background404} alt="alteroute" className="h-1/2 w-1/2" />
      </div>
      <div className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 w-11/12 md:max-w-md ">
        <p className="text-qosblue uppercase font-semibold text-opacity-70 text-center">
          404 ERROR
        </p>
        <p className="font-black text-mST md:text-dH3 text-qosdark text-center my-2">
          This page does not exist.
        </p>
        <p className="text-center text-mlg md:text-dlg">
          The page you are looking for could not be found.
        </p>
        <nav className="py-2 mt-4 bg-opacity-80 bg-qosgray rounded-xl overflow-hidden shadow">
          <Link
            className="flex relative p-4 px-4 rounded-md w-11/12 mx-auto text-qosdark items-center"
            to="/"
          >
            <span className="fi absolute bottom-5  text-dST  text-opacity-70 text-qosdark fi-rr-home text-transparent bg-clip-text bg-gradient-to-bl from-qosblue to-qosred "></span>
            <div className="ml-12">
              <span className="text-dmd block font-semibold text-qosdark text-opacity-70">
                Home
              </span>
              <span className="text-qosdark text-msm md:text-dsm  text-opacity-40">
                This page will redirect you to our home page.
              </span>
            </div>
          </Link>
        </nav>
        <nav className="py-2 mt-2 bg-opacity-80 bg-qosgray rounded-xl overflow-hidden shadow">
          <Link
            className="flex relative p-4 px-4 rounded-md w-11/12 mx-autotext-qosdark items-center"
            to="/contact-us"
          >
            <span className="fi absolute bottom-5  text-dST  text-opacity-70 text-qosdark  fi-rr-bell-school text-transparent bg-clip-text bg-gradient-to-bl from-qosblue to-qosred "></span>
            <div className="ml-12">
              <span className="text-dmd block font-semibold  text-qosdark text-opacity-70">
                Contact Support
              </span>
              <span className="text-qosdark  text-msm md:text-dsm  text-opacity-40">
                Share your problem with us.
              </span>
            </div>
          </Link>
        </nav>
        <nav className="py-2 mt-2 bg-opacity-80 bg-qosgray rounded-xl overflow-hidden shadow">
          <button
            className="flex relative p-4 px-4 rounded-md w-11/12 mx-autotext-qosdark items-center"
            onClick={goBack}
          >
            <span className="fi absolute bottom-3 text-dST text-opacity-70 text-qosdark  fi-rr-redo text-transparent bg-clip-text bg-gradient-to-bl from-qosblue to-qosred "></span>
            <div className="ml-12">
              <span className="text-dmd block font-semibold text-qosdark text-opacity-70">
                Go back
              </span>
            </div>
          </button>
        </nav>
      </div>
    </div>
  );
}
