import { Link } from "react-router-dom";
import Wrapper from "../../components/sectionWrapper";
import { media } from "../../libs/media";
import Messages from "./translations/Secure.json";
import { useLangContext } from "../../components/translateContext";
import buttonText from "../../translations/button.json";
import { Bounce } from "react-reveal";
import { Icon } from "@iconify/react";
export default function Secured2() {
  const { Lang } = useLangContext();
  const Infos = { msg: Messages[Lang], button: buttonText[Lang] };
  return (
    <Wrapper
      childrenClassName="relative"
      className="bg-qoswhite pb-10 md:pb-20 z-10 overflow-hidden relative"
    >
      {/* <div className="mt-10 relative flex-inline  justify-left align-left w-full">
        <div className="mb-4">
          <p className="text-qosorange md:text-x3l font-semibold">
            {Infos.msg.Secured}
          </p>
        </div>

        <div className="w-full flex-inline  md:w-full gap-4 justify-start ">
          <div className="w-full md:w-6/12 ">
            <span className="md:pt-4 md:text-x4l font-semibold md:w-11/12 lg:w-11/12 xl:11/12 ">
              {Infos.msg.HelloText}
            </span>
          </div>
        </div>
      </div> */}

      {/* <div className=" grid md:flex md:w-full  gap-4 mt-14">
        <div className="bg-qoswhite w-full md:w-6/12 py-4">
          <img
            src={media.Secure.Left}
            alt=""
            className="h-full w-full  md:h-full md:w-full   "
          />
        </div>

        <div className="bg-qoswhite w-full md:w-6/12 p-4">
          <div className=" grid gap-4 mb-6">
            <div className="pt-4">
              <span className="font-semibold md:text-x3l">{Infos.msg.FA}</span>
            </div>
            <div className="pt-2 ">
              <p className="md:text-x2l ">{Infos.msg.FAText}</p>
            </div>
          </div>

          <div className=" grid gap-4 mb-6">
            <div className="pt-4">
              <span className="font-semibold md:text-x3l">{Infos.msg.PCI}</span>
            </div>
            <div className="pt-2 ">
              <p className="md:text-x2l ">{Infos.msg.PCIText}</p>
            </div>
          </div>
        </div>
      </div> */}

      <div className="mt-10 relative flex-inline  justify-left align-left w-full">
        <div className="mb-1">
          <h4 className="text-qosorange">{Infos.msg.Get}</h4>
        </div>
      </div>

      <div className=" grid md:flex md:w-full  gap-4 mt-14">
        <div className="bg-qoswhite w-full md:w-6/12 p-1">
          <div className=" grid gap-4 mt-4 mb-6">
            <div className="">
              <h3 className=" md:w-11/12 lg:w-10/12 xl:10/12 ">
                {Infos.msg.Start}
              </h3>
            </div>
            <div className="pt-2 ">
              <p className="md:text-x3l  md:w-11/12 lg:w-10/12 xl:10/12">
                {Infos.msg.GetText}
              </p>
            </div>
          </div>

          <div className="mt-4 md:mt-14">
            <a
              href="https://dashboard.qosic.com/signup"
              className="px-8 inline-block rounded-lg bg-qosorange py-1 shadow-sm text-opacity-90 font-medium btn-sm max-w-max mx-auto m-2"
            >
              <p  className="text-qosgray text-base lg:text-Mobilw20 px-5 py-2 hover:text-opacity-70 font-semibold  inline-block md:ml-1">
                {Infos.button.registerButton}
              </p>
            </a>
            <Link
              to="/contact-us"
              className="rounded-full relative px-3 ml-4  inline-flex items-center p-2 backdrop-blur-3xl text-opacity-70"
            >
              <p className="text-qosorange text-base lg:text-Mobilw20 hover:text-opacity-70  font-semibold inline-block md:ml-1">
                {Infos.button.saleButton}
              </p>

              <Icon
                icon="ion:chevron-forward"
                height={25}
                style={{ color: "orange", fontSize: "20px" }}
              />
            </Link>
          </div>
        </div>
        <div className="w-full md:w-6/12 py-2 rounded-lg">
          <img
            src={media.Secure.Right}
            alt=""
            className="h-full w-full  md:h-full md:w-full "
          />
        </div>
      </div>
    </Wrapper>
  );
}
