import { useEffect, useState } from "react";
import Wrapper from "../../components/sectionWrapper";
import Address from "../../components/address";
import PhoneInput from "react-phone-input-2";
import Messages from "./translations/index.json";
import { useLangContext } from "../../components/translateContext";
import buttonText from "../../translations/button.json";
import inputText from "../../translations/input.json";
import { media } from "../../libs/media";
import Server from "../../libs/server";
import { Link } from "react-router-dom";

const { Facebook, Instagram, LinkedIn, Whatsapp } = media.Social;
const { mailSent } = media.Illustrations;
export default function ContactPageSupport() {
  const { Lang } = useLangContext();
  const [Success, setSuccess] = useState(false);
  const [OtherReason, setOtherReason] = useState(false);
  const [Contact, setContact] = useState({});

  const Infos = {
    msg: Messages[Lang],
    input: inputText[Lang],
    button: buttonText[Lang],
  };

  function handleContactSender(e) {
    e.preventDefault();
    Server.Contact(Contact)
      .then((response) => response && setTimeout(() => setSuccess(true), 200))
      .catch((response) => console.log(response.response));
  }

  function handleInputChange(e) {
    setContact((prev) => {
      prev[e.target.name] = e.target.value;
      return { ...prev };
    });
  }

  useEffect(() => {
    console.log(Contact);
  }, [Contact]);

  return (
    <div
    // style={{
    //   background:
    //     "url('./pictures/authpageBackground.webp') 1% 50% rgba(255,255,255,0.1)",
    // }}
    >
      <Wrapper
        childrenClassName="transform md:scale-90"
        className="py-24 md:py-4"
      >
        <div className="flex flex-col mt-10 md:mt-20 pt-10 mb-10 text-center justify-center bg-qoswhite ">
          <h3 className="md:text-Nwm  text-qosblackText  ">
            {Infos.msg.TitleSupport}
          </h3>
          <p className="mt-2 md:mt-4 md:w-7/12 mx-auto text-center text-qosdark text-base md:text-dlg1">
            {Infos.msg.description1}
          </p>
        </div>
      
        <section className="flex justify-center align-center mt-10 md:flex  ">
      
          <div className="mt-10 w-full">
            <form
              onSubmit={handleContactSender}
              className="w-full flex justify-center md:pl-8 rounded-lg md:flex flex-wrap px-2 py-4 z-0 relative"
            >
              <div
                className={`${
                  Success && "hidden md:hidden"
                } transition-all duration-500 mx-auto md:-ml-0 -mt-2  md:pl-8 md:flex flex-wrap px-2 py-4 z-0 relative`}
              >
                <div className="mx-auto w-full md:w-1/2  px-2">
                  <label className="block text-base md:text-Nw">
                    {" "}
                    {Infos.input.firstname.label}{" "}
                  </label>
                  <input
                    style={styles.input}
                    type="text"
                    className="py-8 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
                    name="firstname"
                    required
                    onChange={handleInputChange}
                    placeholder={Infos.input.firstname.placeholder}
                    value={Contact.firstname}
                  />
                </div>
                <div className="mt-6 mx-auto w-full md:w-1/2 md:mt-0 px-2">
                  <label className="block text-base md:text-Nw">
                    {Infos.input.lastname.label}
                  </label>
                  <input
                    type="text"
                    name="lastname"
                    required
                    className="py-8 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
                    onChange={handleInputChange}
                    placeholder={Infos.input.lastname.placeholder}
                    value={Contact.lastname}
                  />
                </div>
                <div className="mt-10 mx-auto w-full md:w-1/2 md:mt-10 px-2">
                  <label className="block text-base md:text-Nw">
                    {Infos.input.email.label}
                  </label>
                  <input
                    type="email"
                    className="py-8 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
                    name="mail"
                    required
                    onChange={handleInputChange}
                    placeholder={Infos.input.email.placeholder}
                    value={Contact.email}
                  />
                </div>
                <div className="mt-10 mx-auto w-full md:w-1/2 md:mt-10 px-2">
                  <label className="block text-base md:text-Nw ">
                    {Infos.input.tel.label}
                  </label>

                  <input
                    type="phone"
                    className="py-8 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
                    name="phone"
                    required
                    onChange={handleInputChange}
                    placeholder={Infos.input.tel.placeholder}
                    value={Contact.phone}
                  />
                </div>

                <label className="block mt-8 px-2  text-base md:text-Nw ">
                  {Infos.input.help.label}
                </label>
                <div
                  className={`mx-auto  w-full px-2 mt-4 text-qosdark text-opacity-80`}
                >
                  <input
                    type="phone"
                    className="py-8 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
                    name="phone"
                    required
                    onChange={handleInputChange}
                    placeholder={Infos.input.help.placeholder}
                    value={Contact.phone}
                  />
                </div>

                <label className="block mt-8 px-2  text-base md:text-Nw ">
                  {Infos.input.subject.label}
                </label>
                <div
                  className={`mx-auto  w-full px-2 mt-4 text-qosdark text-opacity-80`}
                >
                  <input
                    type="phone"
                    className="p-8 py-8 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
                    name="phone"
                    required
                    onChange={handleInputChange}
                    placeholder={Infos.input.subject.placeholder}
                    value={Contact.phone}
                  />
                </div>

                <label className="block mt-8 px-2  text-base md:text-Nw ">
                  {Infos.input.message.label}
                </label>

                <div
                  className={`mx-auto  h-40 w-full px-2 mt-4 text-qosdark text-opacity-80`}
                >
                  <textarea
                    className="p-8 h-40 border rounded-sm focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
                    name="message"
                    placeholder={Infos.input.message.placeholder}
                    onChange={handleInputChange}
                  ></textarea>
                </div>

                <div className="mt-4 md:mt-20 w-full justify-center align-center flex">
                  <button className="px-8 inline-block rounded-full w-3/5 bg-qosorange py-1 shadow-sm text-opacity-90 font-medium  m-2">
                    <p className="text-qosgray text-base lg:text-Nw py-4 hover:text-qosdark font-semibold  inline-block md:ml-1">
                      {Infos.button.Send}
                    </p>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </section>
      </Wrapper>
    </div>
  );
}

const styles = {
  input: {
    // width:680,
    // height:"42px",

    borderRadius: 4,
  },
  button: {
    // width:680,
    height: "28",
    borderRadius: 8,
  },
};
