import axios from "axios";
const api = true;
const baseUrl = api
  ? "https://blogeditorapi.herokuapp.com"
  : "http://localhost:8280";

const Server = {
  ReadPost: async (query) => {
    return await axios
      .get(`${baseUrl}/posts`, {
        params: { ...query },
      })
      .then((response) => {
        return response.data;
      })
      .catch((reason) => reason.response);
  },
  read: async (query) => {
    return await axios
      .get(`${baseUrl}/post`, {
        params: { ...query },
      })
      .then((response) => response.data.post)
      .catch((reason) => reason);
  },
  Suscribe: async (query) => {
    return await axios
      .post(`${baseUrl}/suscribe`, query)
      .then((response) => {
        if (response.statusText === "OK" && response.status === 200) {
          return true;
        }
        return false;
      })
      .catch((response) => response.response);
  },
  Contact: async (query) => {
    return await axios
      .post(`${baseUrl}/contact`, query)
      .then((response) => {
        if (response.statusText === "OK" && response.status === 200) {
          return true;
        }
        return false;
      })
      .catch((response) => response.response);
  },
};

export default Server;
