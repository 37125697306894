import { lazy, useEffect, Suspense, useState } from "react";
import { Route, useLocation, Switch, useHistory } from "react-router-dom";
import WebFont from 'webfontloader';
import SEO from "./components/seo";
import seo from "./translations/seo.json";
import { Loader } from ".";

import Prism from "prismjs";
import "prismjs/components/prism-markup-templating";
import "prismjs/components/prism-python";
import "prismjs/components/prism-json";
import "prismjs/components/prism-jq";
import "prismjs/components/prism-http";
import "prismjs/components/prism-uri";
import "prismjs/components/prism-php";
import "prismjs/components/prism-rest";
import { useLangContext } from "./components/translateContext";

import MainLayout from "./layouts/MainLayout";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import LandingPage from "./page/LandingPage";
import { PageNotFound } from "./page/404";
import SupportPage from "./page/support/support";
import ContactPageSupport from "./page/contact/contactSupport";

const DigitalFinancePage = lazy(() => import("./page/finance/finance"));
const PricingPage = lazy(() => import("./page/pricing/pricing"));
const EntreprisePage = lazy(() => import("./page/company/company"));
const ContactPage = lazy(() => import("./page/contact/contact"));
const OfflinePaymentPage = lazy(() => import("./page/offlinePayment"));
const OnlinePaymentPage = lazy(() => import("./page/onlinePayment"));
const ShareLinkPage = lazy(() => import("./page/sharableLink"));
const CheckoutPage = lazy(() => import("./page/checkout"));
const TransferPage = lazy(() => import("./page/transfer"));
const StorePage = lazy(() => import("./page/store"));
const DevelopersPage = lazy(() => import("./page/developer/developers"));
const Blog = lazy(() => import("./page/blog"));
const Post = lazy(() => import("./page/post/index.tsx"));
const Sitemap = lazy(() => import("./page/sitemap"));
const TermsAndCondition = lazy(() => import("./page/termsAndCondition"));
const CookiesPolicy = lazy(() => import("./page/cookiesPolicy"));
const MerchantServiceAgreement = lazy(() =>
  import("./page/merchantServiceAgreement")
);
const PrivacyPolicy = lazy(() => import("./page/privacyPolicy"));

const MainPaths = [
  "/",
  "/contact-us/",
  "/digital-finance/",
  "/pricing/",
  "/company/",
  "/docs/",
  "/login/",
  "/register/",
  "/offline-payment/",
  "/online-payment/",
  "/shareLink-payment/",
  "/sitemap/",
  "/terms-and-conditions/",
  "/cookies-policy/",
  "/merchant-service-agreement/",
  "/privacy-policy/",
  "/blog/",
  "/checkout/",
  "/transfer/",
  "/store/",
  "/support/",
  "/contact-support/",
  "/",
  "/contact-us",
  "/digital-finance",
  "/pricing",
  "/company",
  "/docs",
  "/login",
  "/register",
  "/offline-payment",
  "/online-payment",
  "/shareLink-payment",
  "/sitemap",
  "/terms-and-conditions",
  "/blog",
  "/cookies-policy",
  "/merchant-service-agreement",
  "/privacy-policy",
  "/checkout",
  "/transfer",
  "/store",
  "/support",
  "/contact-support",
];

export default function App() {
  const { pathname } = useLocation();
  const history = useHistory();
  const { Lang } = useLangContext();
  const seoEngine = seo[Lang];
  const [HandF, setHandF] = useState(true);



  useEffect(() => {

    Prism.highlightAll();
    if (MainPaths.includes(pathname)) {
      window.scroll(0, 0);
    }
    if (!MainPaths.includes(pathname) && !/\/blog+/g.test(pathname)) {
      setHandF(false);
    } else {
      setHandF(true);
    }
    window.addEventListener("load", function (e) {
      e.preventDefault();
      history.push(`${pathname}`);
    });
    return () => window.scroll(0, 0);
  }, [pathname, history]);

  return (
    <Suspense fallback={<Loader />}>
      {HandF && <Navbar />}
      <MainLayout>
        <Switch>
          <Route exact path="/">
            <SEO
              title={seoEngine.home.title}
              description={seoEngine.home.description}
              keywords={seoEngine.home.keywords}
            />
            <LandingPage />
          </Route>
          <Route exact path={`/transfer/`}>
            {/* <SEO
              title={seoEngine.sharableLink.title}
              description={seoEngine.sharableLink.description}
              keywords={seoEngine.sharableLink.keywords}
            /> */}
            <TransferPage/>
          </Route>
          <Route exact path={`/digital-finance/`}>
            <SEO
              title={seoEngine.MicroFinance.title}
              description={seoEngine.MicroFinance.description}
            />
            <DigitalFinancePage />
          </Route>
          <Route exact path={`/pricing/`}>
            <SEO
              title={seoEngine.pricing.title}
              description={seoEngine.pricing.description}
            />
            <PricingPage />
          </Route>
          <Route exact path={`/company/`}>
            <SEO
              title={seoEngine.enterprise.title}
              description={seoEngine.enterprise.description}
            />
            <EntreprisePage />
          </Route>
          <Route exact path={`/contact-us/`}>
            <SEO
              title={seoEngine.contact.title}
              description={seoEngine.contact.description}
            />
            <ContactPage />
          </Route>
          <Route exact path={`/online-payment/`}>
            <SEO
              title={seoEngine.webBusiness.title}
              description={seoEngine.webBusiness.description}
              keywords={seoEngine.webBusiness.keywords}
            />
            <OnlinePaymentPage />
          </Route>
          <Route exact path={`/offline-payment/`}>
            <SEO
              title={seoEngine.offlinePayment.title}
              description={seoEngine.offlinePayment.description}
              keywords={seoEngine.offlinePayment.keywords}
            />
            <OfflinePaymentPage />
          </Route>
          <Route exact path={`/shareLink-payment/`}>
            <SEO
              title={seoEngine.sharableLink.title}
              description={seoEngine.sharableLink.description}
              keywords={seoEngine.sharableLink.keywords}
            />
            <ShareLinkPage />
          </Route>

          <Route exact path={`/checkout/`}>
            <SEO
              title={seoEngine.sharableLink.title}
              description={seoEngine.sharableLink.description}
              keywords={seoEngine.sharableLink.keywords}
            />
            <CheckoutPage />
          </Route>
         

          <Route exact path={`/store/`}>
            <SEO
              title={seoEngine.sharableLink.title}
              description={seoEngine.sharableLink.description}
              keywords={seoEngine.sharableLink.keywords}
            />
            <StorePage />
          </Route>

          <Route path={`/docs/`}>
            <SEO
              title={seoEngine.documentation.title}
              description={seoEngine.documentation.description}
            />
            <DevelopersPage />
          </Route>
          <Route exact path={`/sitemap/`}>
            <SEO
              title={"QOS site map"}
              description={seoEngine.enterprise.keywords}
            />
            <Sitemap />
          </Route>
          <Route exact path={`/terms-and-conditions/`}>
            <SEO
              title={"QOS terms and conditions"}
              description={seoEngine.enterprise.keywords}
            />
            <TermsAndCondition />
          </Route>
          <Route exact path={`/cookies-policy/`}>
            <SEO
              title={"QOS Cookies Policy"}
              description={seoEngine.enterprise.keywords}
            />
            <CookiesPolicy />
          </Route>
          <Route exact path={`/merchant-service-agreement/`}>
            <SEO
              title={"QOS Merchant service agreement "}
              description={seoEngine.enterprise.keywords}
            />
            <MerchantServiceAgreement />
          </Route>
          <Route exact path={`/privacy-policy/`}>
            <SEO
              title={"QOS Privacy Policy"}
              description={seoEngine.enterprise.keywords}
            />
            <PrivacyPolicy />
          </Route>
          <Route exact path={`/support/`}>
            <SEO
              title={"QOS Support"}
              description={seoEngine.enterprise.keywords}
            />
            <SupportPage/>
          </Route>
          <Route exact path={`/contact-support/`}>
            <SEO
              title={"QOS Contact support"}
              description={seoEngine.enterprise.keywords}
            />
            <ContactPageSupport/>
          </Route>
          <Route exact path={`/blog/`}>
            <SEO
              title={seoEngine.blog.title}
              description={seoEngine.blog.description}
            />
            <Blog />
          </Route>
          <Route exact path={`/blog/post/:title/:pos/:id/:cat?/`}>
            <Post />
          </Route>
          <Route>
            <SEO title={"404"} description={"QOS-Integrated Center"} />
            <PageNotFound />
          </Route>
        </Switch>
      </MainLayout>
      {HandF && <Footer />}
    </Suspense>
  );
}
