import Wrapper from "../../components/sectionWrapper";
import { media } from "../../libs/media";
import { Slide } from "react-reveal";

import { useLangContext } from "../../components/translateContext";
import buttonText from "../../translations/button.json";
export default function Partners() {

  const { Lang } = useLangContext();
  const Infos = {  button: buttonText[Lang] };
  return (
    <Wrapper
      id="Name"
      className="overflow-hidden md:mb-10 z-10 bg-qoswhite relative"
    >
      <svg width="0" height="0" className="fixed">
        <defs>
          <clipPath id="myCrve" clipPathUnits="objectBoundingBox">
            <path
              d="M 0,1
									L 0,.25
                  C .35 0, .65 0 1 .25
									L 1,0
									L 1,1
									Z"
            />
          </clipPath>
        </defs>
      </svg>
      <svg width="0" height="0" className="fixed">
        <defs>
          <clipPath id="myCurveMob" clipPathUnits="objectBoundingBox">
            <path
              d="M 0,1
									L 0,.02
                  C .35 0, .70 0 1 .05
									L 1,0
									L 1,1
									Z"
            />
          </clipPath>
        </defs>
      </svg>
      <div className="w-full text-x5l mt-1 text-center md:text-left ">
         <span className="w-full md:w-6/12 lg:w-5/12  text-Mobilw24  md:font-black md:my-1 md:text-black">
        {Infos.button.trusted}
        </span>
        <p className="text-qosblackgray font-normal  w-full  md:my-8  md:text-Mobilw24 " >
        {Infos.button.trustedDescription}
        </p>
       
      </div>
      <div className="grid grid-cols-2  md:mt-20 md:flex gap-y-8 gap-x-8 my-8 mx-auto items-left justify-left content-center">
        {media.Logos.LandingCustomers.map((Partner, index) => (
          <Slide
            key={`customer-${index}`}
            duration={1000}
            delay={index * 10}
            bottom
          >
            <img
              className="mx-auto md:mx-0 md:h-20 md:w-70  rounded-xl p-1 bg-opacity-80"
              src={Partner}
              alt="QOS qosic parteners mobile money aggregator"
            />
          </Slide>
        ))}
      </div>
    </Wrapper>
  );
}
