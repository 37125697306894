import { useEffect, useState } from "react";
import { media } from "../libs/media";
import { useLangContext } from "./translateContext";

const { Benin, Nigeria, Togo, Ivoire } = media.Flags;

const pays = {
  benin: {
    contry: "Benin",
    flag: Benin,
  },
  togo: {
    contry: "Togo",
    flag: Togo,
  },
  ivoire: {
    contry: "Ivoire",
    flag: Ivoire,
  },
};

export default function DropdownFlag({ Short = true }) {
  const { lang, setLang } = useState("");
  const [open, setOpen] = useState(false);
  console.log("my lang:", lang);
  console.log("my open:", open);

  function Alie() {
    // setLang("Benin");
  }

  useEffect(() => {
    setOpen(false);
    Alie();
  }, [lang]);

  return (
    <div className="inline-block text-left ml-4">
      {/* <p> {Languages[Lang].lang} </p> */}

      <button
        onClick={() => setOpen((prev) => !prev)}
        className="p-1 pb-0.5 min-w-max relative flex"
      >
        {/* <img
          src={Languages[Lang].flag}
          className="h-4 w-8 inline-block"
          alt="flag"
        /> */}

        <img src={Benin} className="h-6 w-10 rounded-sm " alt="flag" />

        {/* <p
          className="inline-block mx-1 text-msm font-bold text-semibold "
          style={{ fontSize: 18 }}
        >
          {Languages[Lang].log}
        </p> */}
        {!Short && (
          <>
            <p className="inline-block mx-2 text-msm rounded-sm">{pays[lang].flag}</p>
            <span className="fi fi-rr-angle-small-down inline-block"></span>
          </>
        )}
      </button>
      <div
        className={`grid grid-flow-row pl-2 shadow-lg origin-top transform transition-all duration-100 rounded-md ${
          !Short && "p-2 bg-qosdark"
        } gap-y-2 mt-2 absolute ${open ? "scale-y-100" : " scale-y-0"}`}
      >
        <button
          // onClick={() => setLang("Benin")}
          className={`${
            lang === "Benin" && "hidden"
          } min-w-max bg-qosgray bg-opacity-10 rounded-lg pr-2`}
        >
          <img
            src={pays["benin"].flag}
            className="h-4 w-8 inline-block rounded-sm"
            alt="flag"
          />
          {/* <p className="inline-block mx-2 text-msm">{pays["benin"].flag}</p>
          {!Short && (
            <p className="inline-block w-6/12 mx-2 text-qosgray text-opacity-70 text-msm">
              {pays["benin"].flag}
            </p>
          )} */}
        </button>
        <button
          // onClick={() => setLang("Togo")}
          className={`${
            lang === "Togo" && "hidden"
          } min-w-max bg-qosgray bg-opacity-10 rounded-lg pr-2`}
        >
          <img
            src={pays["togo"].flag}
            className="h-4 w-8 inline-block rounded-sm"
            alt="flag"
          />
          {/* <p className="inline-block mx-2 text-msm text-bold">
          {pays["togo"].flag}
          </p>
          {!Short && (
            <p className="inline-block w-6/12 mx-2 text-qosgray text-opacity-70 text-msm">
              {pays["togo"].flag}
            </p>
          )} */}
        </button>

        <button
          // onClick={() => setLang("Ivoire")}
          className={`${
            lang === "Ivoire" && "hidden"
          } min-w-max bg-qosgray bg-opacity-10 rounded-lg pr-2`}
        >
          <img
            src={pays["ivoire"].flag}
            className="h-4 w-8 inline-block rounded-sm"
            alt="flag"
          />
          {/* <p className="inline-block mx-2 text-msm text-bold">
          {pays["ivoire"].flag}
          </p>
          {!Short && (
            <p className="inline-block w-6/12 mx-2 text-qosgray text-opacity-70 text-msm">
              {pays["ivoire"].flag}
            </p>
          )} */}
        </button>
      </div>
    </div>
  );
}
